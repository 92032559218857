export function convertForApi (enumKey: string, convertMap: Map<string, string>) {
  let apiValue = ''
  convertMap.forEach((key, value) => {
    if (key === enumKey) {
      apiValue = value
      return
    }
  })
  return apiValue
}
